import React from 'react';
import { useIntl } from 'react-intl';
import styles from './OpeningOfferModal.module.scss';
import { translate } from '../../../../utility/messageTranslator/translate';
import Modal from '../../../../common/Modal/Modal';
import Button from 'src/common/Button/Button';
import { routes } from 'src/config/Router/routes';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

type Props = {
  onClose: () => void;
  isOpen: boolean;
};

export const OpeningOfferModal = ({ onClose, isOpen }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      className={styles.openingOfferModal}
    >
      <div className={styles.mainContentContainer}>
        <div className={styles.topContainer}>
          <div className={cx(styles.highlightedText, styles.openingOffer)}>
            {translate(intl, 'OPENING_OFFER_MODAL.OPENING_OFFER')}
          </div>
          <div className={cx(styles.highlightedText, styles.openingOffer)}>
            {translate(intl, 'OPENING_OFFER_MODAL.DOUBLE_BENEFIT')}
          </div>
        </div>

        <div className={styles.mainTextContainer}>
          {translate(intl, 'OPENING_OFFER_MODAL.MAIN_TEXT')}
        </div>
        <Button
          onClick={() => {
            navigate(routes.subscriptions);
            onClose();
          }}
          className={styles.buyButton}
        >
          {translate(intl, 'OPENING_OFFER_MODAL.BUY_BUTTON')}
        </Button>
        <div
          className={styles.bottomText}
          dangerouslySetInnerHTML={{
            __html: translate(intl, 'OPENING_OFFER_MODAL.BOTTOM_TEXT'),
          }}
        />
      </div>
    </Modal>
  );
};

export default OpeningOfferModal;
